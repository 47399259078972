import React from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import my_styles from "./my-styles";
const styles = my_styles.MUIApp_styles;


let ExportComposition = ({ classes }) => {

  return (
    <Grid container spacing={3} direction="column" alignItems="center">
      <Grid item>
        <br />
        <br />
        <br />
        <br />
        <Typography variant="h3" align="center">Thanks for composing on melodWe!</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" align="center">
          Go back and take a screenshot!
        </Typography>
        <Typography variant="body1" align="center">
          Emailed compositions coming soon.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(ExportComposition);