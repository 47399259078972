import React from 'react';
// import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import my_styles from '../my-styles';
const styles = my_styles.MUICircularLoadButton_styles;

function CircularIntegration({
  classes,
  isLoading,
  isSuccess,
  onClick,
  startIcon,
  type,
  children //text
}) {
  // const buttonClassname = classNames({
  //   [classes.buttonSuccess]: isSuccess
  // });

  console.log(classes.buttonProgress);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button
          type={type}
          variant="contained"
          // className={buttonClassname}
          disabled={isLoading}
          onClick={onClick}
          startIcon={startIcon}
        >
          {children}
        </Button>
        {isLoading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  );
}

export default withStyles(styles)(CircularIntegration);
