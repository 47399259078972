// Update the count down every 1 second
// timeInterval in seconds
// updateFunction called every second, takes in the remaining # seconds
// finishedFunction called at the end
let timerObject = (timeInterval) => (updateFunction, finishedFunction) => {
  let remainingTime = timeInterval;
  let timer = setInterval(() => {
    // Display the result in the element with id="demo"
    updateFunction(remainingTime);

    remainingTime -= 1;

    // If the count down is finished, write some text
    if (remainingTime < 0) {
      clearInterval(timer);
      finishedFunction();
    }
  }, 1000);
};

export default timerObject;
