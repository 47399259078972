import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Redirect } from "react-router";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";

import { SocketIOSubscribeToRoom } from "./realtime/notifications";
import CircularLoadButton from "./components/MUICircularLoadButton";
import { Link as RRLink } from "react-router-dom";
import { makeAPICall } from "./api";
import my_styles from "./my-styles";
const styles = my_styles.MUIApp_styles;

let JoinGroup = ({ classes, match }) => {
  let [message, setMessage] = useState("");
  let [errorMessage, setErrorMessage] = useState("");
  let [redirectNow, setRedirectNow] = useState(false);
  let [roomName, setRoomName] = useState("");
  let [requestInProgress, setRequestInProgress] = useState(false);
  let [canSubmit, setCanSubmit] = useState(false);

  let storedUser = JSON.parse(localStorage.getItem(`melodWe_user`));

  // Subscribe to real-time notifications
  useEffect(() => {
    // THIS IS DONE ON MOUNT:
    // console.log(`MOUNTING QUESTION VOTING FOR ${qid}`);
    if (storedUser && message) {
      let unsubscribe_later = SocketIOSubscribeToRoom(
        roomName,
        storedUser.username,
        () => {},
        () => { setRedirectNow(true); },
        false
      ); // this also subscribes
      // THIS IS DONE ON UNMOUNT!!!
      return async () => {
        // console.log('UNMOUNTING');
        unsubscribe_later();
      };
    }
  }, [message]);

  let getCompositionID = async (room_name) => {
    setErrorMessage("");
    try {
      let res = await makeAPICall("GET", "/api/compositions/room/" + room_name);
      let body = await res.json();
      if (res.status === 200) {
        const comp_id = body.composition_id;
        console.log(`Composition ID: ${comp_id}`);
        return comp_id;
      } else {
        setErrorMessage("This room doesn't exist.");
        return null;
      }
    } catch (err) {
      setErrorMessage("Unable to connect to server.");
      return null;
    }
  };

  let joinComposition = async () => {
    setMessage("");
    setErrorMessage("");
    setRequestInProgress(true);
    try {
      const comp_id = await getCompositionID(roomName);
      setRequestInProgress(false);
      if (!comp_id) {
        return false;
      } else {
        return true;
      }
    } catch (err) {
      setRequestInProgress(false);
      return false;
    }
  };

  let handleJoinComposition = async (event) => {
    event.preventDefault();
    if (canSubmit) {
      let joined = await joinComposition();
      if (joined) {
        setMessage(`Joined Room ${roomName.toUpperCase()}!`);
      }
    }
  };

  let validRoomName = (room_name) => {
    // eslint-disable-next-line no-useless-escape
    var re = /^[a-zA-Z]{4}$/;
    return re.test(String(room_name).toLowerCase());
  };

  let handleRoomNameCheck = (event) => {
    let { value } = event.target;
    const newRoomName = value;
    if (!validRoomName(newRoomName)) {
      setCanSubmit(false);
      setErrorMessage("Room Codes have 4 letters.");
    } else {
      setCanSubmit(true);
      setErrorMessage("");
    }
    setRoomName(newRoomName);
  };

  // TODO: this is so messed up I literally have no idea how to fix it otherwise
  let quick = () => {
    localStorage.setItem(`melodWe_refreshed`, JSON.stringify(false));
    return true;
  };

  return (
    <div>
      {redirectNow ? quick() && (
        <Redirect to={{ pathname: `/note-selection/` + roomName }} />
      ) : (
        <Card className={classes.backgroundPaper}>
          {!storedUser || !storedUser.username || !storedUser.email ? (
            <>
              <CardActions className={classes.centerChildren}>
                <Typography variant="body1" align="center">
                  Please first login here:
                </Typography>
                <Button component={RRLink} variant="contained" to={"/"}>
                  Login
                </Button>
              </CardActions>
            </>
          ) : (
            <>
              <Typography variant="h2" align="center">
                melodWe
              </Typography>
              <Grid
                container
                spacing={3}
                direction="column"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h5" align="center">
                    Welcome, {storedUser.username}!
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" align="center">
                    Please enter a room code:
                  </Typography>
                </Grid>
                <form
                  onSubmit={(event) => handleJoinComposition(event)}
                  autoComplete="off"
                >
                  <TextField
                    required
                    variant="filled"
                    type="room_name"
                    name="room_name"
                    label="Room Code"
                    fullWidth
                    margin="normal"
                    value={roomName}
                    onChange={(event) => handleRoomNameCheck(event)}
                  />
                  {errorMessage && (
                    <Typography color="error" variant="body1" align="center">
                      {errorMessage}
                    </Typography>
                  )}
                  <CardActions className={classes.centerChildren}>
                    <CircularLoadButton
                      type="submit"
                      isLoading={requestInProgress}
                    >
                      Join Room
                    </CircularLoadButton>
                  </CardActions>
                  <br />
                </form>
              </Grid>
            </>
          )}
          {message && (
            <>
              <Typography variant="body1" align="center">
                {message}
              </Typography>
              <Typography variant="body1" align="center">
                Waiting on leader to start...
              </Typography>
            </>
          )}
          {message && <LinearProgress />}
        </Card>
      )}
    </div>
  );
};

export default withStyles(styles)(JoinGroup);
