import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
// import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
// import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Paper from "@material-ui/core/Paper";
import CircularLoadButton from "./components/MUICircularLoadButton";
// import { Link as RRLink } from "react-router-dom";
import { Redirect } from "react-router";
import { makeAPICall } from "./api";
import my_styles from "./my-styles";
const styles = my_styles.MUIApp_styles;

let Welcome = ({ classes, currentUser, updateUser, updateRoom }) => {
  let [message, setMessage] = useState("");
  let [errorMessage, setErrorMessage] = useState("");
  let [newGroupInProgress, setNewGroupInProgress] = useState(false);
  let [joinGroupInProgress, setJoinGroupInProgress] = useState(false);
  let [values, setValues] = useState({
    username: "",
    email: "",
    can_submit: true,
  });
  let [confirmEmail, setEmailMessage] = useState({ message: "" });
  let [nextPageRoute, setNextPageRoute] = useState(`/new-group`);
  let [redirectNow, setRedirectNow] = useState(false);

  let reserveARoom = async (values) => {
    setMessage("");
    setErrorMessage("");
    try {
      let res = await makeAPICall("POST", "/api/compositions", values);
      let body = await res.json();
      setMessage(body.message);
      if (res.status === 201) {
        const wordArray = body.message.split(" ");
        setNextPageRoute(`/new-group/${wordArray[wordArray.length - 1]}`);
        return true;
      }
      return false;
    } catch (err) {
      nextPageRoute === `/new-group`
        ? setNewGroupInProgress(false)
        : setJoinGroupInProgress(false);
      setErrorMessage("Unable to connect to server.");
      return false;
    }
  };

  let navigateToComposition = async (values) => {
    setMessage("");
    setErrorMessage("");
    nextPageRoute === `/new-group`
      ? setNewGroupInProgress(true)
      : setJoinGroupInProgress(true);
    try {
      let res = await makeAPICall("POST", "/api/users", values);
      let body = await res.json();
      nextPageRoute === `/new-group`
        ? setNewGroupInProgress(false)
        : setJoinGroupInProgress(false);
      if (res.status === 201) {
        setMessage(body.message);
        localStorage.setItem(
          "melodWe_user",
          body.user ? JSON.stringify(body.user) : "{}"
        );
        console.log(`Redirecting to ${nextPageRoute}`);
        if (nextPageRoute === `/join-group`) {
          setRedirectNow(true);
        }
        // updateUser(body.user);
      } else if (res.status === 409) {
        nextPageRoute === `/new-group`
          ? setNewGroupInProgress(true)
          : setJoinGroupInProgress(true);
        res = await makeAPICall("PUT", "/api/users", values);
        body = await res.json();
        nextPageRoute === `/new-group`
          ? setNewGroupInProgress(false)
          : setJoinGroupInProgress(false);
        setMessage("Welcome Back!");
        if (res.status === 200 || res.status === 201) {
          localStorage.setItem(
            "melodWe_user",
            body.user ? JSON.stringify(body.user) : "{}"
          );
          console.log(`Redirecting to ${nextPageRoute}`);
          if (nextPageRoute === `/join-group`) {
            setRedirectNow(true);
          }
          // updateUser(body.user);
        }
      }

      if (nextPageRoute === `/new-group`) {
        const success = await reserveARoom({ leader_email: body.user.email });
        if (success) {
          setRedirectNow(true);
        }
      }
    } catch (err) {
      setNewGroupInProgress(false);
      setJoinGroupInProgress(false);
      setErrorMessage("Unable to connect to server.");
    }
  };

  let handleMakeNewComposition = (event) => {
    event.preventDefault();
    if (values.can_submit) {
      navigateToComposition(values);
    }
  };

  let handleChange = (event) => {
    // update state based on form input changes
    let { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  // from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  let validEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  let handleEmailCheck = (event) => {
    let { name, value } = event.target;
    const newState = { ...values, [name]: value };
    if (!validEmail(newState.email)) {
      setValues({ ...newState, can_submit: false });
      setEmailMessage({ message: "*Invalid email" });
    } else {
      setValues({ ...newState, can_submit: true });
      setEmailMessage({});
    }
  };

  return (
    <>
      {redirectNow ? (
        <Redirect to={{ pathname: nextPageRoute }} />
      ) : (
        <>
          <Paper className={classes.backgroundPaper}>
            <Typography variant="h1" align="center">
              melodWe
            </Typography>
            <Typography variant="h5" align="center">
              A new platform for collaborative music composition.
            </Typography>
            {/* <br /> */}
            <form
              onSubmit={(event) => handleMakeNewComposition(event)}
              autoComplete="off"
            >
              <CardContent>
                {/* <Typography variant="body1" align="center">
                  Please enter a username and email address:
                </Typography> */}
                <TextField
                  required
                  variant="filled"
                  type="username"
                  name="username"
                  label="Username"
                  fullWidth
                  margin="normal"
                  value={values.username}
                  onChange={(event) => handleChange(event)}
                />
                <TextField
                  required
                  variant="filled"
                  type="email"
                  name="email"
                  label="Email"
                  fullWidth
                  margin="normal"
                  value={values.email}
                  onChange={(event) => handleEmailCheck(event)}
                />
                {confirmEmail.message && (
                  <Typography color="error" variant="body1">
                    {confirmEmail.message}
                  </Typography>
                )}
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <CircularLoadButton
                      type="submit"
                      isLoading={newGroupInProgress}
                      onClick={() => setNextPageRoute(`/new-group`)}
                    >
                      Create New Group
                    </CircularLoadButton>
                  </Grid>
                  <Grid item xs={6}>
                    <CircularLoadButton
                      type="submit"
                      isLoading={joinGroupInProgress}
                      onClick={() => setNextPageRoute(`/join-group`)}
                    >
                      Enter Existing Composition Room
                    </CircularLoadButton>
                  </Grid>
                </Grid>
              </CardContent>
            </form>
          </Paper>
          {errorMessage && (
            <Typography color="error" variant="body1" align="center">
              {errorMessage}
            </Typography>
          )}
          {message && (
            <Typography variant="body1" align="center">
              {message}
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default withStyles(styles)(Welcome);
