import React, { Component } from "react";
import RootApp from './components/RootApp';

class App extends Component {
  render() {
    return (
      <RootApp />
    );
  }
}

export default App;