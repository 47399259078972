import { createMuiTheme } from '@material-ui/core/styles';
import { lightGreen, purple, grey } from '@material-ui/core/colors';

const drawerWidth = 200;

const styles = {
  MUIApp_styles: theme => ({
    bold: {
      fontWeight: '590'
    },
    centered: {
      margin: '0 auto', // https://learnlayout.com/max-width.html
      maxWidth: 600
    },
    centerChildren: {
      justifyContent: 'center'
    },
    centerText: {
      textAlign: 'center'
    },
    halfWidthInlineBlock: {
      display: 'inline-block',
      width: '50%'
    },
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    fab: {
      margin: theme.spacing(1)
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    rightAlignButton: {
      display: 'inline-block',
      textAlign: 'right',
      paddingRight: '5%',
      width: '20%'
    },
    paginateButtons: {
      flexGrow: 1,
      display: 'inline-block',
      width: '80%'
    },
    marginTop: {
      marginTop: theme.spacing(2)
    },
    zeroMargin: {
      margin: 0
    },
    paper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
      outline: 'none',
      borderRadius: '5px',
      top: '50%',
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      maxWidth: '90%'
    },
    backgroundPaper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
      outline: 'none',
      borderRadius: '5px',
      top: '50%',
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      maxWidth: '90%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    headerBar: {
      overflow: 'hidden',
      fontSize: '25px',
      background: '#8e7cc3'
    }
  }),

  MUITabChooser_styles: theme => ({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 20
    },
    logButton: {
      marginRight: 12,
      padding: 12
    },
    thinFont: {
      fontWeight: 400
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
      textAlign: 'right'
    }
  }),

  MUICircularLoadButton_styles: theme => ({
    bold: {},
    centered: {},
    centerChildren: {},
    centerText: {},
    halfWidthInlineBlock: {},
    root: {
      display: 'inline-flex',
      alignItems: 'center'
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative'
    },
    buttonSuccess: {
      backgroundColor: lightGreen[500],
      '&:hover': {
        backgroundColor: lightGreen[700]
      }
    },
    buttonProgress: {
      color: lightGreen[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    }
  }),

  // ------------------------------------------------------------------
  // Create 2 themes with a light/dark palette.
  // See https://material-ui.com/customization/themes/#type-light-dark-theme
  darkTheme: createMuiTheme({
    palette: {
      primary: lightGreen,
      type: 'dark' // Switching the dark mode on is a single property value change.
    },
    typography: { useNextVariants: true } // avoids deprecated warning
  }),

  defaultTheme: createMuiTheme({
    palette: {
      primary: purple,
      secondary: grey,
      background: {
        default: "#B6A6CA",
      },
      type: 'light' // Switching the dark mode on is a single property value change.
    },
    typography: { 
      useNextVariants: true, // avoids deprecated warning
      fontFamily: [
        'Comfortaa',
        'Arial'
      ].join(',')
    },
  })
};

export default styles;
