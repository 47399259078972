import React from "react";
import { Route } from "react-router-dom";

import Welcome from "../Welcome";
import NewGroup from "../NewGroup";
import JoinGroup from "../JoinGroup";
// import WaitingRoom from "../WaitingRoom";
import ExportComposition from "../ExportComposition";
import { NoteSelectionPageWrapper } from "../NoteSelectionPageWrapper";

// import "../setupProxy"; // TODO: REMOVE WHEN HOSTING

import { withStyles } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import my_styles from "../my-styles";
const styles = my_styles.MUIApp_styles;
const defaultTheme = my_styles.defaultTheme;

let RootApp = () => {
  // let initialUser = JSON.parse(localStorage.getItem(`melodWe_user`)); // code to read user from localStorage

  // let [roomName, updateRoom] = useState("");
  // let [currentUser, updateUser] = useState(() => {
  //   return initialUser ? initialUser : {};
  // });

  return (
    <MuiThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <div>
        <Route
          exact
          path="/"
          component={Welcome}
          // render={(props) => (
          //   <Welcome
          //     currentUser={currentUser}
          //     updateUser={updateUser}
          //     updateRoom={updateRoom}
          //     {...props}
          //   />
          // )}
        />
        <Route exact path={`/new-group/:room_name`} component={NewGroup} />
        <Route
          exact
          path={`/note-selection/:room_name`}
          render={(props) => (
            <NoteSelectionPageWrapper isLeader={false} {...props} />
          )}
        />
        <Route
          exact
          path={`/note-selection-leader/:room_name`}
          render={(props) => (
            <NoteSelectionPageWrapper isLeader={true} {...props} />
          )}
        />
        <Route exact path={`/join-group`} component={JoinGroup} />
        {/* <Route exact path={`/waiting-room`} component={WaitingRoom} /> */}
        <Route
          exact
          path={`/export-composition`}
          component={ExportComposition}
        />
      </div>
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(RootApp);
