import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const WholeNote = () => (
  <SvgIcon
    // width="1.25in"
    // height="0.97in"
    // viewBox="0 0 90.28 69.84"
    width="0.39in"
    height="0.31in"
    viewBox="0 0 27.96 22.14"
    fontSize="large"
    style={{ transform: "translateY(10px) scale(0.5)" }}
  >
    <path
      d="M323.43,434.09c-3.88,0-7.92-1.2-10.64-4.07s-4.13-7.12-2.42-10.88c3.81-8.37,16.15-9,22.78-4,3.16,2.4,5.33,6.34,4.05,10.35C335.37,431.25,329.1,434.08,323.43,434.09Zm.31-3.93a13.65,13.65,0,0,0,4.89-1.07c4.85-2.08,6.49-7.12,2.09-10.68s-11.43-3.28-15.27.78a5.69,5.69,0,0,0,0,8.22A11.63,11.63,0,0,0,323.74,430.16Z"
      transform="translate(-309.62 -411.96)"
    />
  </SvgIcon>
);

export const HalfNote = () => (
  <SvgIcon
    width="0.39in"
    height="0.85in"
    viewBox="0 0 28.12 61.33"
    fontSize="large"
  >
    <path
      d="M330.93,438.14v-38c0-.67,0-1.34,0-2a1.83,1.83,0,0,1,1.77-1.76,2,2,0,0,1,2.18,1.64,10.11,10.11,0,0,1,.07,2c0,15.24,0,30.48,0,45.72a10.51,10.51,0,0,1-6.2,9.95,16.55,16.55,0,0,1-15.56.12c-3.84-1.91-6.73-5.53-6.32-10.13a9,9,0,0,1,3.07-6.07,14.86,14.86,0,0,1,14-3.74C326.31,436.41,328.51,437.33,330.93,438.14Zm-10.1,15.35a11,11,0,0,0,7.82-2.65c2.75-2.27,3-5.95.48-8.22a12.32,12.32,0,0,0-16.3-.06,5.11,5.11,0,0,0-.29,7.7C314.76,452.7,317.69,453.38,320.83,453.49Z"
      transform="translate(-306.89 -396.37)"
    />
  </SvgIcon>
);

export const QuarterNote = () => (
  <SvgIcon
    width="0.39in"
    height="0.85in"
    viewBox="0 0 28.37 61.43"
    fontSize="large"
  >
    <path
      d="M336.09,421.69c0,7-.26,14,.07,21,.38,8.16-4.91,12.25-11.11,13.24-5.41.86-10.43-.09-14.47-4.23a9.52,9.52,0,0,1,1-14.28c5.55-4.26,11.57-4.59,17.84-1.84,2.47,1.08,2.45,1.14,2.45-1.56q0-17.36,0-34.71c0-3.45.59-4.79,2.15-4.47,2.68.54,2.07,2.83,2.08,4.65C336.12,406.87,336.09,414.28,336.09,421.69Z"
      transform="translate(-307.81 -394.76)"
    />
  </SvgIcon>
);

export const EighthNote = () => (
  <SvgIcon
    width="0.57in"
    height="0.85in"
    viewBox="0 0 40.71 61.01"
    fontSize="large"
  >
    <path
      d="M327.5,419.09V401.38c0-.59,0-1.17,0-1.75,0-1.26.6-2.11,1.92-2.12s1.81.88,1.95,2.1a68.33,68.33,0,0,0,1,7.58c1.49,6.39,4.5,12.19,7.27,18.08a95,95,0,0,1,3.86,9.36,13,13,0,0,1-.41,9.43,3.51,3.51,0,0,1-.73,1.28,1.86,1.86,0,0,1-1.29.42c-.27,0-.6-.58-.66-.94a3.32,3.32,0,0,1,.09-1.72c2-4.37.55-8.42-1.2-12.38C337,425.44,334.48,420.23,332,415c-.09-.2-.22-.39-.33-.59-.11.25-.32.49-.32.74,0,2.83,0,5.66,0,8.48v22.21c0,6.21-2.57,9.83-8.57,11.7S311,458.68,306.3,454a9.08,9.08,0,0,1,.76-13.77,16.44,16.44,0,0,1,18.33-1.67,5.27,5.27,0,0,1,.87.49c1,.79,1.24.26,1.24-.73,0-3.66,0-7.32,0-11Z"
      transform="translate(-303.5 -397.51)"
    />
  </SvgIcon>
);

export const SixteenthNote = () => (
  <SvgIcon
    width="0.57in"
    height="0.85in"
    viewBox="0 0 41.24 61.52"
    fontSize="large"
  >
    <path
      d="M325.88,435V396.76c0-.66,0-1.33,0-2a2,2,0,0,1,1.8-2.05c1.19-.19,1.79.7,2.22,1.63a3,3,0,0,1,.16,1.23c0,6.59,2.53,12.45,5.13,18.3,2.26,5.09,4.69,10.1,6.8,15.25a11.23,11.23,0,0,1,.7,7.57,3.08,3.08,0,0,0,0,1.73c.85,2.58-.14,4.93-.84,7.31a2.06,2.06,0,0,1-.58,1.08c-.5.33-1.23.74-1.7.59a1.94,1.94,0,0,1-1-1.46,7.82,7.82,0,0,1,.59-2.1c.22-.92.74-1.77-.16-2.77a2.54,2.54,0,0,1-.26-2.28,2.65,2.65,0,0,0-.32-2.78c-2.07-3.51-4.17-7-6.23-10.53-.7-1.21-1.31-2.48-2.11-4V430c0,4,0,8,0,12a10.58,10.58,0,0,1-5.34,9.78c-5.94,3.66-15.38,3.39-20.65-2.47a9.72,9.72,0,0,1-1.17-10.7c2.61-4.62,6.88-6.28,11.81-6.7A17.37,17.37,0,0,1,325.88,435Zm13.69-4.25-9.5-20.55C331.34,417.86,335,424.49,339.57,430.73Z"
      transform="translate(-301.85 -392.68)"
    />
  </SvgIcon>
);

export const WholeRest = () => (
  <SvgIcon
    width="0.53in"
    height="0.21in"
    viewBox="0 0 38 15.33"
    fontSize="large"
  >
    <path
      d="M304.5,420.5v-5h38v5h-5.65v10.33H310.22V420.5Z"
      transform="translate(-304.5 -415.5)"
    />
  </SvgIcon>
);

export const HalfRest = () => (
  <SvgIcon
    width="0.52in"
    height="0.22in"
    viewBox="0 0 37.5 15.7"
    fontSize="large"
  >
    <path
      d="M305,431.51v-5h5.43v-10.7h26.35v10.7h5.72v5Z"
      transform="translate(-305 -415.81)"
    />
  </SvgIcon>
);

export const QuarterRest = () => (
  <SvgIcon
    width="0.34in"
    height="1.04in"
    viewBox="0 0 24.4 74.69"
    fontSize="large"
  >
    <path
      d="M1186.47,1217.47c.08-5.59,2.83-9.47,7.86-11.85,1.25-.6,1.31-.72.58-1.7-.35-.46-.79-.85-1.18-1.28-2.18-2.39-4.37-4.78-6.52-7.19-1.47-1.65-2.12-4.41-1.16-5.92a6.72,6.72,0,0,1,2.86-2.3c2.29-1.16,4.61-2.25,6.91-3.38,1.26-.63,1.27-.65.62-1.8l-9-15.74c-.74-1.29-1.51-2.56-2.23-3.86a7.06,7.06,0,0,1-.39-1.12,4.24,4.24,0,0,1,.94.58q6.8,6.78,13.57,13.59,4.57,4.59,9.14,9.2c1,1.06,1,1.11-.17,1.73-2.87,1.48-5.75,2.93-8.62,4.4-.37.18-.74.37-1.1.58-1.38.79-1.4.8-.93,2.26,1.14,3.54,2.23,7.09,3.47,10.59a7.62,7.62,0,0,0,1.71,2.39,2.89,2.89,0,0,1,1,2.91,6.12,6.12,0,0,0,.25,3.77,3.93,3.93,0,0,1,0,.91c-.19-.15-.46-.25-.57-.44a3.53,3.53,0,0,0-2.87-2.07,1.71,1.71,0,0,1-1.12-.91c-.63-1.35-1.66-1.09-2.7-.79a7.83,7.83,0,0,0-5.52,7,13.59,13.59,0,0,0,1.33,6.75c1,2.17,2.28,4.21,3.47,6.29.49.87,1.14,1.65,1.56,2.54a2.41,2.41,0,0,1-.86,3.08,2.31,2.31,0,0,1-3-.4,9.86,9.86,0,0,1-1.3-1.51c-2.88-4.09-5.25-8.42-6-13.46C1186.5,1219.38,1186.52,1218.46,1186.47,1217.47Z"
      transform="translate(-1184.87 -1161.33)"
    />
  </SvgIcon>
);

export const EighthRest = () => (
  <SvgIcon
    width="0.25in"
    height="0.59in"
    viewBox="0 0 18.25 42.56"
    fontSize="large"
  >
    <path
      d="M333.45,405.43c-.06.22-.15.7-.3,1.17l-11,33a9.25,9.25,0,0,1-.83,2.06,1.35,1.35,0,0,1-1.16.53c-.59-.16-1.28-.49-1-1.41.63-1.88,1.25-3.77,1.88-5.65q4.37-13.06,8.72-26.14c.13-.39.19-.81.35-1.19.23-.54.1-.84-.47-.65-2.19.72-4.41,1.41-6.54,2.28s-3.93,1-5.81-.38c-1.51-1.12-2.51-3.6-2-5.2.76-2.39,2.92-4.22,5-4.2a5.21,5.21,0,0,1,5.1,4.77c.17,1.15.35,1.32,1.42,1,1.66-.49,3.3-1.07,5-1.58C333,403.47,333.47,403.86,333.45,405.43Z"
      transform="translate(-315.2 -399.64)"
    />
  </SvgIcon>
);

export const SixteenthRest = () => (
  <SvgIcon
    width="0.35in"
    height="0.6in"
    viewBox="0 0 25.02 43.22"
    fontSize="large"
  >
    <path
      d="M322.29,435.54c-1.45.77-2.89,1.55-4.35,2.3a5.31,5.31,0,0,1-6.05-.54,5.9,5.9,0,0,1-1.73-5.87,5.39,5.39,0,0,1,4.89-4,5.3,5.3,0,0,1,5.55,4c.1.38.16.77.24,1.14,2.8-.4,3.93-2.14,4.65-4.59,1.25-4.21,2.79-8.35,4.18-12.52.38-1.17.67-2.37,1.09-3.87a22.14,22.14,0,0,0-6.55,2,5.6,5.6,0,0,1-5,.31,6.09,6.09,0,0,1-3.33-5.43,5.77,5.77,0,0,1,4-5,5.19,5.19,0,0,1,5.79,1.9,18.22,18.22,0,0,1,1.44,3.6,18.15,18.15,0,0,0,1.83-.39c1.19-.36,2.36-.83,3.56-1.14,2-.52,2.91.46,2.28,2.43-1,3.17-2.09,6.31-3.15,9.46l-7,20.8c-.55,1.66-1.07,3.32-1.64,5-.33,1-.91,1.64-2.05,1.24s-1.26-1.24-.94-2.23c.61-1.9,1.27-3.78,1.91-5.67.28-.85.55-1.7.82-2.55Z"
      transform="translate(-309.94 -403.35)"
    />
  </SvgIcon>
);
