import React from "react";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { withStyles } from "@material-ui/core/styles";
import {
  WholeNote,
  HalfNote,
  QuarterNote,
  EighthNote,
  SixteenthNote,
  WholeRest,
  HalfRest,
  QuarterRest,
  EighthRest,
  SixteenthRest,
} from "./svgs/svg_strings";
import my_styles from "./my-styles";
const styles = my_styles.MUIApp_styles;

let ADurationSelector = ({ classes, duration, setDuration, lockedIn }) => {

  const handleDuration = (event, newDuration) => {
    if (!lockedIn) {
      setDuration(newDuration);
    }
  };

  return (
    <div>
      <ToggleButtonGroup exclusive value={duration} onChange={handleDuration}>
        <ToggleButton key={"1n"} value="1n" disabled={lockedIn}>
          <WholeNote />
        </ToggleButton>
        ,
        <ToggleButton key={"2n"} value="2n" disabled={lockedIn}>
          <HalfNote />
        </ToggleButton>
        ,
        <ToggleButton key={"4n"} value="4n" disabled={lockedIn}>
          <QuarterNote />
        </ToggleButton>
        ,
        <ToggleButton key={"8n"} value="8n" disabled={lockedIn}>
          <EighthNote />
        </ToggleButton>
        ,
        <ToggleButton key={"16n"} value="16n" disabled={lockedIn}>
          <SixteenthNote />
        </ToggleButton>
        ,
      </ToggleButtonGroup>
      <br />
      <br />
      <ToggleButtonGroup exclusive value={duration} onChange={handleDuration}>
        <ToggleButton key={"1r"} value="1r" disabled={lockedIn}>
          <WholeRest />
        </ToggleButton>
        ,
        <ToggleButton key={"2r"} value="2r" disabled={lockedIn}>
          <HalfRest />
        </ToggleButton>
        ,
        <ToggleButton key={"4r"} value="4r" disabled={lockedIn}>
          <QuarterRest />
        </ToggleButton>
        ,
        <ToggleButton key={"8r"} value="8r" disabled={lockedIn}>
          <EighthRest />
        </ToggleButton>
        ,
        <ToggleButton key={"16r"} value="16r" disabled={lockedIn}>
          <SixteenthRest />
        </ToggleButton>
        ,
      </ToggleButtonGroup>
    </div>
  );
};

const DurationSelector = withStyles(styles)(ADurationSelector);
export { DurationSelector };
