import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router";
import { Typography, IconButton } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import { withStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import EmojiPeopleRoundedIcon from "@material-ui/icons/EmojiPeopleRounded";
import AccessibilityNewRoundedIcon from "@material-ui/icons/AccessibilityNewRounded";
import AccessibilityRoundedIcon from "@material-ui/icons/AccessibilityRounded";
import DirectionsRunRoundedIcon from "@material-ui/icons/DirectionsRunRounded";
import DirectionsWalkRoundedIcon from "@material-ui/icons/DirectionsWalkRounded";
import BathtubRoundedIcon from "@material-ui/icons/BathtubRounded";
import AirlineSeatReclineNormalRoundedIcon from "@material-ui/icons/AirlineSeatReclineNormalRounded";
import AirlineSeatReclineExtraRoundedIcon from "@material-ui/icons/AirlineSeatReclineExtraRounded";
import SportsHandballRoundedIcon from "@material-ui/icons/SportsHandballRounded";
import AirlineSeatFlatAngledRoundedIcon from "@material-ui/icons/AirlineSeatFlatAngledRounded";
import AirlineSeatFlatRoundedIcon from "@material-ui/icons/AirlineSeatFlatRounded";
import AirlineSeatIndividualSuiteRoundedIcon from "@material-ui/icons/AirlineSeatIndividualSuiteRounded";

import {
  SocketIOSubscribeToRoom,
  SocketIOStartComposition,
} from "./realtime/notifications";
import CircularLoadButton from "./components/MUICircularLoadButton";
import { Link as RRLink } from "react-router-dom";
import { makeAPICall } from "./api";
import my_styles from "./my-styles";
const styles = my_styles.MUIApp_styles;

const icons = [
  <EmojiPeopleRoundedIcon color="inherit" />,
  <AccessibilityNewRoundedIcon color="inherit" />,
  <AccessibilityRoundedIcon color="inherit" />,
  <DirectionsRunRoundedIcon color="inherit" />,
  <DirectionsWalkRoundedIcon color="inherit" />,
  <BathtubRoundedIcon color="inherit" />,
  <AirlineSeatReclineNormalRoundedIcon color="inherit" />,
  <AirlineSeatReclineExtraRoundedIcon color="inherit" />,
  <SportsHandballRoundedIcon color="inherit" />,
  <AirlineSeatFlatAngledRoundedIcon color="inherit" />,
  <AirlineSeatFlatRoundedIcon color="inherit" />,
  <AirlineSeatIndividualSuiteRoundedIcon color="inherit" />,
];

let NewGroup = ({ classes, match }) => {
  let [message, setMessage] = useState("");
  let [errorMessage, setErrorMessage] = useState("");
  let [redirectNow, setRedirectNow] = useState(false);
  let [startCompositionInProgress, setStartCompositionInProgress] = useState(
    false
  );

  let storedUser = JSON.parse(localStorage.getItem(`melodWe_user`));
  const room_name = match && match.params && match.params.room_name;
  const nextPageRoute = `/note-selection-leader/` + room_name;

  let [members, setMembers] = useState(
    storedUser ? [{ username: storedUser.username + ` (you)` }] : []
  );

  let addMember = (username, joined) => {
    if (joined) {
      setMembers((members) => [...members, { username: username }]);
    } else {
      setMembers((members) =>
        members.filter((user) => user.username !== username)
      );
    }
  };

  // Subscribe to real-time notifications
  useEffect(() => {
    // THIS IS DONE ON MOUNT:
    // console.log(`MOUNTING QUESTION VOTING FOR ${qid}`);
    if (storedUser) {
      let unsubscribe_later = SocketIOSubscribeToRoom(
        room_name,
        storedUser.username,
        addMember,
        () => {},
        true
      ); // this also subscribes
      // THIS IS DONE ON UNMOUNT!!!
      return () => {
        // console.log('UNMOUNTING');
        unsubscribe_later();
      };
    }
  }, [room_name]); // NEED ROOM NAME OR ELSE DOESN'T WORK!!!

  let getCompositionID = async (room_name) => {
    setErrorMessage("");
    try {
      let res = await makeAPICall("GET", "/api/compositions/room/" + room_name);
      let body = await res.json();
      if (res.status === 200) {
        const comp_id = body.composition_id;
        console.log(`Composition ID: ${comp_id}`);
        return comp_id;
      } else {
        setErrorMessage("Warning: This composition doesn't exist.");
        return null;
      }
    } catch (err) {
      setErrorMessage("Unable to connect to server.");
      return null;
    }
  };

  let startComposition = async () => {
    setMessage("");
    setErrorMessage("");
    setStartCompositionInProgress(true);
    try {
      let composition_id = await getCompositionID(room_name);

      if (!composition_id) {
        setStartCompositionInProgress(false);
        return false;
      }

      let res = await makeAPICall(
        "PUT",
        "/api/compositions/" + composition_id,
        {
          start: true,
          leader_email: storedUser.email,
        }
      );
      let body = await res.json();
      console.log(body);

      setStartCompositionInProgress(false);

      if (res.status === 200) {
        SocketIOStartComposition(room_name, storedUser.username, true, () => {
          setRedirectNow(true);
        });
        return true;
      } else {
        setErrorMessage(
          "It appears either the composition does not exist, it already started, or you are not the leader."
        );
        return false;
      }
    } catch (err) {
      setErrorMessage("Unable to connect to server.");
      return false;
    }
  };

  // let random_number_0_to_exclusive = (num) => {
  //   return Math.floor(Math.random() * num);
  // };

  // TODO: this is so messed up I literally have no idea how to fix it otherwise
  let quick = () => {
    localStorage.setItem(`melodWe_refreshed`, JSON.stringify(false));
    return true;
  };

  return (
    <div>
      {redirectNow ? quick() && (
        <Redirect to={{ pathname: nextPageRoute }} />
      ) : (
        <Paper className={classes.backgroundPaper}>
          {!storedUser || !storedUser.username || !storedUser.email ? (
            <>
              <CardActions className={classes.centerChildren}>
                <Typography variant="body1" align="center">
                  Please first login here:
                </Typography>
                <Button component={RRLink} variant="contained" to={"/"}>
                  Login
                </Button>
              </CardActions>
            </>
          ) : (
            <>
              <Typography variant="h2" align="center">
                melodWe
              </Typography>
              <Typography variant="h5" align="center">
                Your Room Code is:
              </Typography>
              <Typography variant="h1" align="center">
                {room_name.toUpperCase()}
              </Typography>
              <Typography variant="body1" align="center">
                Share this code with your composition group!
              </Typography>
              <br />
              <Typography variant="body1" align="center">
                Once everyone has joined, click start composing.
              </Typography>

              <CardActions className={classes.centerChildren}>
                <CircularLoadButton
                  isLoading={startCompositionInProgress}
                  onClick={async () => {
                    await startComposition();
                  }} //setNextPageRoute(`/note-selection`)}
                  startIcon={<MusicNoteIcon />}
                >
                  Start Composing!
                </CircularLoadButton>
              </CardActions>
              {errorMessage && (
                <Typography color="error" variant="body1" align="center">
                  {errorMessage}
                </Typography>
              )}
              {message && (
                <Typography variant="body1" align="center">
                  {message}
                </Typography>
              )}
              <Card className={classes.centered}>
                <br />
                <Typography variant="h5" align="center">
                  {members.length} Group Members:
                </Typography>
                <br />
                <LinearProgress />
                <List dense={true}>
                  {/* {generate(
                    <ListItem>
                      <ListItemAvatar>
                        <EmojiPeopleRoundedIcon color="primary" />
                      </ListItemAvatar>
                      <ListItemText
                        primary="Single-line item"
                        secondary={"Secondary text"}
                      />
                    </ListItem>
                  )} */}
                  {members.map((obj, index) => {
                    return (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          <IconButton color="primary">
                            {icons[index % icons.length]}
                          </IconButton>
                        </ListItemAvatar>
                        <ListItemText
                          primary={obj.username}
                          // secondary={"Secondary text"}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Card>
            </>
          )}
        </Paper>
      )}
    </div>
  );
};

export default withStyles(styles)(NewGroup);
