import React, { useState, useEffect } from "react";
import { Router as RRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { NoteSelectionPage } from "./NoteSelectionPage";
import { SocketIOSimpleSubscribeToRoom } from "./realtime/notifications";
import my_styles from "./my-styles";
const styles = my_styles.MUIApp_styles;

const Pitches = [];
const Durations = [];
let NoteIdx = 0;

function ANoteSelectionPageWrapper({ classes, match, isLeader }) {
  const room_name = match && match.params && match.params.room_name;
  let storedUser = JSON.parse(localStorage.getItem(`melodWe_user`));
  if (!JSON.parse(localStorage.getItem(`melodWe_refreshed`))) {
    localStorage.setItem(`melodWe_refreshed`, JSON.stringify(true));
    window.location.reload(false);
  }

  let [timerRestart, setTimerRestart] = useState(false);
  let [compositionEnded, setCompositionEnded] = useState(false);

  let receiveVote = (note_index, pitch, duration) => {
    if (!note_index || !pitch || !duration) {
      console.log("apparently no votes were cast");
      if (!compositionEnded) {
        setTimerRestart(true);
      }
      return;
    }

    Pitches.push(duration.endsWith("r") || pitch === "r" ? "C2" : pitch); //duration.endsWith('r') ? 'r' : pitch);
    Durations.push(duration);
    NoteIdx++;
    console.log(Pitches, Durations);
    if (!compositionEnded) {
      setTimerRestart(true);
    }
  };

  // Subscribe to room
  useEffect(() => {
    if (storedUser) {
      let unsubscribe_later = SocketIOSimpleSubscribeToRoom(
        room_name,
        storedUser.username,
        receiveVote,
        () => {
          setCompositionEnded(true);
        }
      ); // this also subscribes
      // THIS IS DONE ON UNMOUNT!!!
      return async () => {
        // console.log('UNMOUNTING');
        unsubscribe_later();
      };
    }
  }, [room_name]);

  return (
    <NoteSelectionPage
      roomName={room_name}
      Pitches={Pitches}
      Durations={Durations}
      NoteIdx={NoteIdx}
      setTimerRestart={setTimerRestart}
      timerRestart={timerRestart}
      isLeader={isLeader}
      compositionEnded={compositionEnded}
    />
  );
}

const NoteSelectionPageWrapper = withStyles(styles)(ANoteSelectionPageWrapper);
export { NoteSelectionPageWrapper, Pitches, Durations, NoteIdx };
