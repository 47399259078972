import React from "react";
import Sketch from "react-p5";
import * as Tone from "tone";

// import {selected_duration} from "./duration_state";
import { Pitches, Durations, NoteIdx } from "./NoteSelectionPageWrapper";

let selected_pitch;
let selected_y;
let note_start;
let staff_width;
const synth = new Tone.PolySynth().toMaster();

// don't know why this worked: pulled these out of pitchselector
// this took me ~6 hours to figure out
let selected_y_pos;
let gap_size;
let staff_start;

let PitchSelector = ({ selected_duration, setPitch, setDuration, lockedIn, compositionEnded }) => {
  // let img;
  const setup = (p5, canvasParentRef) => {
    gap_size = p5.windowHeight / 3 / 6;
    p5.createCanvas(p5.windowWidth, p5.windowHeight / 3).parent(
      canvasParentRef
    ); // use parent to render canvas in this ref (without that p5 render this canvas outside your component)
    staff_start = 0; // changed this p5.windowWidth / 4
    staff_width = p5.windowWidth; // changed this p5.windowWidth / 2;
    // img = p5.loadImage("../api/note-selection/treble.png");
    note_start = staff_start + gap_size / 2;
  };

  const windowResized = (p5) => {
    gap_size = p5.windowHeight / 3 / 6;
    p5.resizeCanvas(p5.windowWidth, p5.windowHeight / 3);
    p5.stroke("white");
    p5.fill("white");
    p5.rect(0, 0, p5.windowWidth, p5.windowHeight);
    staff_width = p5.windowWidth; // changed this p5.windowWidth / 2;
    staff_start = 0; // changed this p5.windowWidth / 4;
    note_start = staff_start + gap_size / 2;
  };

  const draw = (p5) => {
    p5.stroke("white");
    // p5.rect(staff_start, 25, (staff_width), 50);

    //p5.stroke("white");
    p5.fill("white");
    p5.rect(staff_start - 10, 0, staff_width + 20, gap_size * 8);

    //p5.rect(staff_start, 25, staff_width, 130);

    p5.stroke("black");
    p5.strokeWeight(8);

    //console.log('drawing staffs');
    p5.line(staff_start, gap_size * 1, staff_start + staff_width, gap_size * 1);
    p5.line(staff_start, gap_size * 2, staff_start + staff_width, gap_size * 2);
    p5.line(staff_start, gap_size * 3, staff_start + staff_width, gap_size * 3);
    p5.line(staff_start, gap_size * 4, staff_start + staff_width, gap_size * 4);
    p5.line(staff_start, gap_size * 5, staff_start + staff_width, gap_size * 5);

    if (selected_duration && !compositionEnded) {
      if (selected_duration.includes("r")) {
        selected_y_pos = undefined;
        selected_pitch = undefined;
      }
    }

    if (!lockedIn) {
      fillSection(selected_y_pos, p5, "#B20000"); //"#c38e7c"
    }
    
    highlight(p5.mouseX, p5.mouseY, p5);

    //work on for loop around this chunk
    let i;
    for (i = 0; i < NoteIdx; i++) {
      if (Durations[i]) {
        if (Durations[i].includes("n")) {
          placeNote(p5, getYIDXsFromNoteName(Pitches[i]), Durations[i], Pitches[i], "black");
        } else {
          placeRest(p5, Durations[i], "black");
        }
        if (Durations[i].includes("16")) {
          note_start += gap_size * 1.75;
        } else if (Durations[i].includes("8")) {
          note_start += gap_size * 2;
        } else if (Durations[i].includes("4")) {
          note_start += gap_size * 3;
        } else if (Durations[i].includes("2")) {
          note_start += gap_size * 4;
        } else if (Durations[i].includes("1")) {
          note_start += gap_size * 5;
        }
      }
    }

    if (selected_duration && !compositionEnded) {
      if (selected_duration.includes("n")) {
        placeNote(p5, selected_y_pos, selected_duration, selected_pitch, "#12494f");
      } else {
        placeRest(p5, selected_duration, "#12494f");
      }
    }

    note_start = staff_start + gap_size / 2;

    //document.getElementById("indicator").innerHTML = selected_duration;

    // p5.image(img, 50, 50);
    // NOTE: Do not use setState in draw function or in functions that is executed in draw function... pls use normal variables or class properties for this purposes
  };

  const mouseClicked = (p5) => {
    if (!lockedIn) {
      let tempY = getFillBounds(p5.mouseX, p5.mouseY, p5, true); // sets selected pitch also
      if (tempY) {
        selected_y_pos = tempY;
        if (!selected_duration) {
          setDuration("4n");
          selected_duration = "4n";
        }
        if (selected_duration && selected_duration.includes("n")) {
          // synth.triggerRelease(["E4", "F4", "G4", "A4", "B4", "C5", "D5", "E5", "F5"]);
          synth.triggerAttackRelease(selected_pitch, "8n"); //selected_duration);
        }
        //document.getElementById("indicator").innerHTML = "right after set";
        //document.getElementById("displayPitch").innerHTML = selected_duration;
      }
    }
  };

  const highlight = (x, y, p5) => {
    let highlightY = getFillBounds(x, y, p5, false);

    if (highlightY && highlightY !== selected_y_pos) {
      fillSection(highlightY, p5, "#8e7cc3");
    }
  };

  const getYIDXsFromNoteName = (note_name) => {
    const note_names = ["F5", "E5", "D5", "C5", "B4", "A4", "G4", "F4", "E4"];
    const y_pos = [gap_size * 1, 1, gap_size * 2, 2, gap_size * 3, 3, gap_size * 4, 4, gap_size * 5];
    let id = 0;
    id = Math.max(id, note_names.findIndex((item) => item === note_name));
    return y_pos[id];
  };

  const getFillBounds = (x, y, p5, isSetting) => {
    let rY;
    let temp_selected_pitch;

    if (x >= staff_start && x <= staff_start + staff_width) {
      if (y >= gap_size * 1 - 8 && y <= gap_size * 1 + 8) {
        rY = gap_size * 1;
        temp_selected_pitch = "F5";
      } else if (y >= gap_size * 1 + 8 && y <= gap_size * 2 - 8) {
        rY = 1;
        temp_selected_pitch = "E5";
      } else if (y >= gap_size * 2 - 8 && y <= gap_size * 2 + 8) {
        rY = gap_size * 2;
        temp_selected_pitch = "D5";
      } else if (y >= gap_size * 2 + 8 && y <= gap_size * 3 - 8) {
        rY = 2;
        temp_selected_pitch = "C5";
      } else if (y >= gap_size * 3 - 8 && y <= gap_size * 3 + 8) {
        rY = gap_size * 3;
        temp_selected_pitch = "B4";
      } else if (y >= gap_size * 3 + 8 && y <= gap_size * 4 - 8) {
        rY = 3;
        temp_selected_pitch = "A4";
      } else if (y >= gap_size * 4 - 8 && y <= gap_size * 4 + 8) {
        rY = gap_size * 4;
        temp_selected_pitch = "G4";
      } else if (y >= gap_size * 4 + 8 && y <= gap_size * 5 - 8) {
        rY = 4;
        temp_selected_pitch = "F4";
      } else if (y >= gap_size * 5 - 8 && y <= gap_size * 5 + 8) {
        rY = gap_size * 5;
        temp_selected_pitch = "E4";
      }
    }

    if (isSetting && temp_selected_pitch) {
      selected_pitch = temp_selected_pitch;
      setPitch(selected_pitch);
      selected_y = rY;
      //setPitch(temp_selected_pitch);
    }
    return rY;
  };

  const fillSection = (y, p5, color) => {
    p5.stroke(color);
    p5.fill(color);
    if (
      y === gap_size * 1 ||
      y === gap_size * 2 ||
      y === gap_size * 3 ||
      y === gap_size * 4 ||
      y === gap_size * 5
    ) {
      p5.strokeWeight(10);
      //console.log(`drawing line with color: ${color}`);
      p5.line(staff_start, y, staff_start + staff_width, y);
    } else if (y === 1 || y === 2 || y === 3 || y === 4) {
      p5.rect(staff_start, gap_size * y + 8, staff_width, gap_size - 16);
    }
  };

  const placeNote = (p5, y_pos, duration, selected_pitch, color) => {
    if (!y_pos || !duration || !selected_pitch) {
      return;
    }
    p5.stroke(color);
    p5.strokeWeight(6);
    let ellipse_center_y = y_pos;
    if (ellipse_center_y <= 4) {
      ellipse_center_y = (gap_size / 2) * y_pos * 2 + gap_size / 2;
    }
    let line_start_x;
    let line_start_y;

    if (
      selected_pitch === "E4" ||
      selected_pitch === "F4" ||
      selected_pitch === "G4" ||
      selected_pitch === "A4"
    ) {
      line_start_x = note_start + gap_size;
      line_start_y = ellipse_center_y - gap_size * 2;
    } else {
      line_start_x = note_start;
      line_start_y = ellipse_center_y + gap_size * 2;
    }
    if (duration === "1n" || duration === "2n") {
      p5.fill("rgba(0,0,0,0)");
    } else {
      p5.fill(color);
    }
    p5.ellipse(
      // TODO: I CHANGED THIS BECAUSE IT WAS CAUSING CONSTANT UPDATES
      note_start + gap_size / 2,
      ellipse_center_y,
      gap_size,
      gap_size - 10
      // note_start + gap_size / 2,
      // ellipse_center_y,
      // gap_size,
      // gap_size - 10
    );
    if (duration !== "1n") {
      //console.log(`drawing note stem`);
      p5.line(line_start_x, line_start_y, line_start_x, ellipse_center_y);
    }
    if (duration === "8n" || duration === "16n") {
      p5.strokeWeight(4);
      p5.fill("rgba(0,0,0,0)");
      if (
        selected_pitch === "E4" ||
        selected_pitch === "F4" ||
        selected_pitch === "G4" ||
        selected_pitch === "A4"
      ) {
        p5.bezier(
          line_start_x,
          line_start_y,
          line_start_x + gap_size / 8,
          line_start_y + gap_size,
          line_start_x + (gap_size / 4) * 3,
          line_start_y + (gap_size / 8) * 11,
          line_start_x + gap_size / 2,
          line_start_y + (gap_size / 8) * 15
        );
      } else {
        p5.bezier(
          line_start_x,
          line_start_y,
          line_start_x + gap_size / 8,
          line_start_y - gap_size,
          line_start_x + (gap_size / 4) * 3,
          line_start_y - (gap_size / 4) * 2,
          line_start_x + gap_size / 2,
          line_start_y - (gap_size / 4) * 5
        );
      }

      if (duration === "16n") {
        if (
          selected_pitch === "E4" ||
          selected_pitch === "F4" ||
          selected_pitch === "G4" ||
          selected_pitch === "A4"
        ) {
          p5.bezier(
            line_start_x,
            line_start_y + gap_size / 2,
            line_start_x + gap_size / 8,
            line_start_y + (gap_size / 2) * 3,
            line_start_x + (gap_size / 4) * 3,
            line_start_y + (gap_size / 8) * 13,
            line_start_x + gap_size / 2,
            line_start_y + (gap_size / 8) * 17
          );
        } else {
          p5.bezier(
            line_start_x,
            line_start_y - gap_size / 4,
            line_start_x + gap_size / 8,
            line_start_y - (gap_size / 4) * 5,
            line_start_x + (gap_size / 4) * 3,
            line_start_y - (gap_size / 4) * 3,
            line_start_x + gap_size / 2,
            line_start_y - (gap_size / 8) * 11
          );
        }
      }
    }
  };

  const placeRest = (p5, duration, color) => {
    if (!duration) {
      return;
    }
    p5.stroke(color);
    p5.strokeWeight(4);
    p5.fill(color);
    if (duration === "1r") {
      p5.rect(note_start, gap_size * 2, gap_size, gap_size / 2);
    } else if (duration === "2r") {
      p5.rect(note_start, (gap_size / 2) * 5, gap_size, gap_size / 2);
    } else if (duration === "4r") {
      //p5.line(staff_start+gap_size/2, gap_size*3 + gap_size/2, staff_start+gap_size, gap_size*4 +gap_size/4);
      p5.triangle(
        note_start + gap_size / 2,
        gap_size * 1 + gap_size / 2,
        note_start + gap_size + gap_size / 4,
        gap_size * 2 + gap_size / 4,
        note_start + gap_size,
        gap_size * 2 + (gap_size / 8) * 3
      );
      p5.strokeWeight(13);
      //console.log(`drawing rest 1`);
      p5.line(
        note_start + gap_size,
        gap_size * 2 + gap_size / 4,
        note_start + gap_size / 2,
        gap_size * 2 + gap_size / 2
      );
      p5.strokeWeight(4);
      //p5.line(staff_start+gap_size/2, gap_size*4 + gap_size/2, staff_start+gap_size, gap_size*5 +gap_size/4);
      p5.triangle(
        note_start + (gap_size / 4) * 3,
        gap_size * 2 + gap_size / 2,
        note_start + (gap_size / 16) * 7,
        gap_size * 2 + (gap_size / 8) * 5,
        note_start + gap_size,
        gap_size * 3 + gap_size / 4
      );
      p5.strokeWeight(8);
      p5.noFill();
      p5.bezier(
        note_start + gap_size,
        gap_size * 3 + gap_size / 4,
        note_start + gap_size,
        gap_size * 3,
        note_start,
        gap_size * 3 + gap_size / 4,
        note_start + (gap_size / 4) * 3,
        gap_size * 4 + gap_size / 4
      );
    } else if (duration === "8r" || duration === "16r") {
      //console.log(`drawing rest 2`);
      p5.line(
        note_start + gap_size,
        gap_size * 2 + gap_size / 2,
        note_start + gap_size / 2,
        gap_size * 4
      );
      p5.circle(
        note_start + gap_size / 2,
        gap_size * 2 + gap_size / 2,
        gap_size / 3
      );
      //console.log(`drawing rest 3`);
      p5.line(
        note_start + gap_size / 2,
        gap_size * 2 + (gap_size / 32) * 21,
        note_start + gap_size,
        gap_size * 2 + gap_size / 2
      );
      if (duration === "16r") {
        p5.circle(
          note_start + gap_size / 4,
          gap_size * 2 + (gap_size / 2) * 3,
          gap_size / 3
        );
        //console.log(`drawing rest 4`);
        p5.line(
          note_start + gap_size / 4,
          gap_size * 2 + (gap_size / 32) * 21 + gap_size,
          note_start + (gap_size / 8) * 5,
          gap_size * 2 + (gap_size / 2) * 3
        );
      }
    }
  };

  return (
    <Sketch
      setup={setup}
      draw={draw}
      mouseClicked={mouseClicked}
      windowResized={windowResized}
    />
  );
};

//export default PitchSelector;
export { PitchSelector, selected_pitch, selected_y, staff_width };
