import io from "socket.io-client"; // uses stand-alone build

console.log("Setting up notification alert routing with socket.io");
const socketIoUrl = `${window.location.origin}`;
const roomSocket = io.connect(`${socketIoUrl}/compositions/room`, {
  path: `${process.env.PUBLIC_URL}/api/socket.io`,
});

// only leader triggers this
let SocketIOStartComposition = (
  room_name,
  username,
  startComposition,
  startCompositionFunction
) => {
  if (startComposition) {
    console.log(`starting composition in room ${room_name}`);
    roomSocket.emit(`startcomposition`, {
      room_name: room_name,
      username: username,
    });
    startCompositionFunction();
  }
};

// only leader triggers this
// let SocketIOEndComposition = (
//   room_name,
//   username,
//   endComposition,
//   endCompositionFunction
// ) => {
//   if (endComposition) {
//     console.log(`ending composition in room ${room_name}`);
//     roomSocket.emit(`endcomposition`, {
//       room_name: room_name,
//       username: username,
//     });
//     endCompositionFunction();
//   }
// };

let SocketIOGetVoteResult = (room_name, username, getVoteResult, endComposition) => {
  // roomSocket.on(`voteresult`, ({ pitch, duration, note_index }) => {
  //   console.log(
  //     `gote vote result: { pitch: ${pitch}, duration: ${duration}, note_index: ${note_index} }`
  //   );
  // });

  if (getVoteResult) {
    console.log(`getting vote result in room ${room_name}`);
    roomSocket.emit(`getvoteresult`, {
      room_name: room_name,
      username: username,
      end_composition: endComposition
    });
  }
};

let SocketIOSimpleRoom = (room_name, username) => {
  console.log(`subscribing to room ${room_name}`);

  // subscribe here:
  roomSocket.emit(`subscribe`, {
    // Since rooms are server-side abstractions, need to give room_name for server to deal with rooms
    room_name: room_name,
    username: username,
  });
};

let SocketIOSimpleSubscribeToRoom = (
  room_name,
  username,
  // shouldGetNotesSoFar,
  receiveVoteResultFunction,
  onCompositionEndFunction
  // notesSoFarFunction
) => {
  SocketIOSimpleRoom(room_name, username);

  // roomSocket.on(`notessofar`, ({ notes }) => {
  //   notesSoFarFunction();
  //   console.log('got the notes:')
  //   console.log(notes);
  // });

  // if (shouldGetNotesSoFar) {
  //   console.log('getting notes so far');
  //   roomSocket.emit(`getnotessofar`, {
  //     room_name: room_name,
  //     username: username
  //   });
  // }

  // console.log('listening on composition ending messages');
  // roomSocket.on(`compositionended`, () => {
  //   onCompositionEndFunction();
  // });

  console.log('listening on voteresult messages');
  roomSocket.on(`voteresult`, ({ pitch, duration, note_index, end_composition }) => {
    console.log(
      `got vote result: { pitch: ${pitch}, duration: ${duration}, note_index: ${note_index}, end_composition: ${end_composition} }`
    );
    if (end_composition) {
      onCompositionEndFunction();
    }

    receiveVoteResultFunction(note_index, pitch, duration);
  });

  return async () => {
    console.log(`unsubscribing from ${room_name}`);
    await roomSocket.emit(`unsubscribe`, {
      room_name: room_name,
      username: username,
    });

    console.log(`removing all listeners ${room_name}`);
    roomSocket.removeAllListeners();
  }; // for closing connection
};

// people subscribe to composition room names, wait for composition to start
let SocketIOSubscribeToRoom = (
  room_name,
  username,
  groupMembersUpdateFunction,
  startCompositionFunction,
  isLeader
) => {
  SocketIOSimpleRoom(room_name, username);

  roomSocket.on(`subscribe`, () => {
    console.log(`SOMEONE SUBSCRIBED`);
  });

  if (isLeader) {
    roomSocket.on(`groupmembersupdate`, ({ username, joined, left }) => {
      console.log(`SOMEONE SUBSCRIBED: ${username}, ${joined}`);
      groupMembersUpdateFunction(username, joined);
    });

    roomSocket.emit(`whoishere`, { room_name: room_name, username: username });
  }

  if (!isLeader) {
    // leader starts a composition
    roomSocket.on(`startcomposition`, () => startCompositionFunction());

    // leader asks whoishere, joiners respond with iamhere.
    // Still need room_name so server can multiplex rooms within the namespace
    roomSocket.on(`whoishere`, () => {
      roomSocket.emit(`iamhere`, { room_name: room_name, username: username });
    });
  }

  // unsubscribe later:
  return async () => {
    console.log(`unsubscribing from ${room_name}`);

    await roomSocket.emit(`unsubscribe`, {
      // Since rooms are server-side abstractions, need to give room_name for server to deal with rooms
      room_name: room_name,
      username: username,
    });

    roomSocket.removeAllListeners();
  }; // for closing connection
};

// people subscribe to room name, wait for messages to send their votes.
let SocketIOVoting = (
  room_name,
  username,
  lockedIn,
  current_note_index,
  current_pitch,
  current_duration
) => {
  if (lockedIn) {
    console.log(
      `sending vote: { pitch: ${current_pitch}, duration: ${current_duration}, note_index: ${current_note_index} }`
    );

    roomSocket.emit(`sendvote`, {
      username: username,
      room_name: room_name,
      pitch: current_pitch,
      duration: current_duration,
    });
  }

  return async () => {};
};

export {
  SocketIOSubscribeToRoom,
  SocketIOStartComposition,
  // SocketIOEndComposition,
  SocketIOVoting,
  SocketIOGetVoteResult,
  SocketIOSimpleSubscribeToRoom,
};
