import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Link as RRLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import PanToolIcon from "@material-ui/icons/PanTool";
import LockIcon from "@material-ui/icons/LockRounded";
import LockOpenIcon from "@material-ui/icons/LockOpenRounded";
import LoopIcon from "@material-ui/icons/Loop";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";

import * as Tone from "tone";

import CircularLoadButton from "./components/MUICircularLoadButton";
import { makeAPICall } from "./api";
import {
  SocketIOVoting,
  SocketIOGetVoteResult,
} from "./realtime/notifications";

import "./app.css";
import timerObject from "./components/timer";

import { DurationSelector } from "./duration_state";
import {
  PitchSelector,
  selected_pitch,
  selected_y,
  staff_width,
} from "./pitch_state";
import { Paper } from "@material-ui/core";

import my_styles from "./my-styles";
const styles = my_styles.MUIApp_styles;

//import getPitch from "./pitch_state";
Tone.Transport.bpm.value = 200;
const synth = new Tone.PolySynth().toMaster(); //.toDestination();
const timeInterval = 20;

function ANoteSelectionPage({
  classes,
  match,
  roomName,
  Pitches,
  Durations,
  NoteIdx,
  setTimerRestart,
  timerRestart,
  isLeader,
  compositionEnded,
}) {
  let storedUser = JSON.parse(localStorage.getItem(`melodWe_user`));
  let room_name = roomName;

  let [errorMessage, setErrorMessage] = useState("");
  let [lockedIn, setLockedIn] = useState(false);
  let [duration, setDuration] = useState(null);
  let [pitch, setPitch] = useState(null);
  let [timeRemaining, setTimeRemaining] = useState(null);

  // Subscribe to real-time notifications
  useEffect(() => {
    console.log(`selected note: ${pitch}, ${duration}`);
    if (storedUser) {
      let unsubscribe_later = SocketIOVoting(
        room_name,
        storedUser.username,
        lockedIn,
        NoteIdx,
        pitch,
        duration,
        () => {}
      ); // this also subscribes
      // THIS IS DONE ON UNMOUNT!!!
      return async () => {
        // console.log('UNMOUNTING');
        unsubscribe_later();
      };
    }
  }, [room_name, pitch, duration, lockedIn]);

  let isValidNote = (pitch, duration) => {
    return duration ? pitch || duration.endsWith("r") : false;
  };

  if (
    !compositionEnded &&
    (timerRestart || (!timeRemaining && timeRemaining !== 0))
  ) {
    timerObject(timeInterval)(setTimeRemaining, () => setLockedIn(true));
    setTimerRestart(false);
    if (lockedIn) {
      setLockedIn(false);
    }
  }

  const pushNulls = (note_sequence, num_nulls) => {
    for (let i = 0; i < num_nulls; i++) {
      note_sequence.push(null);
    }
  }

  const transformPitchesAndDurations = () => {
    let note_sequence = [];
    for (let i = 0; i < Durations.length; i++) {
      const note_duration = Durations[i];
      switch (note_duration) {
        case "1n":
          note_sequence.push(Pitches[i]);
          pushNulls(note_sequence, 15);
          break;
        case "2n":
          note_sequence.push(Pitches[i]);
          pushNulls(note_sequence, 7);
          break;
        case "4n":
          note_sequence.push(Pitches[i]);
          pushNulls(note_sequence, 3);
          break;
        case "8n":
          note_sequence.push(Pitches[i]);
          pushNulls(note_sequence, 1);
          break;
        case "16n":
          note_sequence.push(Pitches[i]);
          break;
        case "1r":
          pushNulls(note_sequence, 16);
          break;
        case "2r":
          pushNulls(note_sequence, 8);
          break;
        case "4r":
          pushNulls(note_sequence, 4);
          break;
        case "8r":
          pushNulls(note_sequence, 2);
          break;
        case "16r":
          pushNulls(note_sequence, 1);
          break;
        default:
          break;
      }
    }
    return note_sequence;
  };

  const playComposition = () => {
    const notes = transformPitchesAndDurations();
    console.log(notes);
    // create a new sequence with the synth and notes
    const synthPart = new Tone.Sequence(
      function(time, note) {
        synth.triggerAttackRelease(note, "10hz", time);
      },
      notes,
      "8n"
    );
    synthPart.loop = 1;
    synthPart.start();
    Tone.Transport.start();
  };

  const lockIn = () => {
    if (isValidNote(pitch, duration)) {
      setErrorMessage("");
      setLockedIn(true);
    } else {
      setErrorMessage("Please select a pitch and duration first.");
    }
  };

  let getCompositionID = async (room_name) => {
    try {
      let res = await makeAPICall("GET", "/api/compositions/room/" + room_name);
      let body = await res.json();
      if (res.status === 200) {
        const comp_id = body.composition_id;
        console.log(`Composition ID: ${comp_id}`);
        return comp_id;
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  };

  // we won't actually await for this, just let it run in the background
  let endComposition = async () => {
    try {
      let composition_id = await getCompositionID(room_name);

      if (!composition_id) {
        return false;
      }

      let res = await makeAPICall(
        "PUT",
        "/api/compositions/" + composition_id,
        {
          end: true,
          leader_email: storedUser.email,
        }
      );
      let body = await res.json();
      console.log(body);

      if (res.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <div>
      {!storedUser || !storedUser.username || !storedUser.email ? (
        <Card className={classes.backgroundPaper}>
          <CardActions className={classes.centerChildren}>
            <Typography variant="body1" align="center">
              Please first login here:
            </Typography>
            <Button component={RRLink} variant="contained" to={"/"}>
              Login
            </Button>
          </CardActions>
        </Card>
      ) : (
        <>
          <div className={classes.headerBar}>
            <Grid container spacing={10} className={classes.centerChildren}>
              <Grid item xs={4}>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    variant="h5"
                    align="left"
                    style={{
                      color: "white",
                      margin: "10px",
                    }}
                  >
                    melodWe
                  </Typography>
                </Link>
              </Grid>

              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  align="center"
                  style={{
                    color: "white",
                    margin: "10px",
                  }}
                >
                  Welcome, {storedUser ? storedUser.username : "unnamed human"}!
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography
                  variant="body1"
                  align="right"
                  style={{
                    color: "white",
                    margin: "10px",
                  }}
                >
                  Room Code: {room_name.toUpperCase()}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div>
            {compositionEnded ? (
              <Grid
                container
                spacing={3}
                direction="column"
                alignItems="center"
                style={{ margin: "20px" }}
              >
                <Grid item>
                  <Typography variant="h3" align="center">
                    Thanks for composing on melodWe!
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" align="center">
                    Take a screenshot!
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={0} direction="row" alignItems="center">
                <Grid item xs={4}>
                  {(timeRemaining || timeRemaining === 0) && (
                    <Paper
                      elevation={1}
                      style={{
                        width: "75px",
                        backgroundColor:
                          timeRemaining || timeRemaining === 0
                            ? timeRemaining < 6
                              ? timeRemaining < 3
                                ? "#db4848"
                                : "#db4848" //"#FABC2A") :
                              : "white"
                            : "white",
                        float: "left",
                        margin: "20px",
                      }}
                    >
                      <Typography
                        variant="h3"
                        align="center"
                        style={{ padding: "7px", paddingTop: "10px" }}
                      >
                        {timeRemaining}
                      </Typography>
                    </Paper>
                  )}
                </Grid>

                {isLeader && (
                  <Grid item xs={4} align="center">
                    {timeRemaining !== 0 ? (
                      <Button
                        variant="contained"
                        startIcon={<PanToolIcon />}
                        style={{
                          textDecoration: "none",
                          // float: "right",
                          // marginRight: "20px",
                          backgroundColor: "#db4848", //"#FFAC81",
                          margin: "20px",
                        }}
                        disabled={true}
                      >
                        Done Composing
                      </Button>
                    ) : (
                      // <Link
                      //   to="/export-composition"
                      //   style={{ textDecoration: "none" }}
                      // >
                      <Button
                        variant="contained"
                        startIcon={<PanToolIcon />}
                        style={{
                          textDecoration: "none",
                          // float: "right",
                          // marginRight: "20px",
                          backgroundColor: "#db4848", //"#FFAC81",
                          margin: "20px",
                        }}
                        onClick={async () => {
                          SocketIOGetVoteResult(
                            room_name,
                            storedUser.username,
                            true,
                            true
                          );
                        await endComposition();}
                        }
                      >
                        Done Composing
                      </Button>
                      // </Link>
                    )}
                  </Grid>
                )}

                {isLeader && (
                  <Grid item xs={4} align="right">
                    <Button
                      variant="contained"
                      startIcon={<LoopIcon />}
                      style={{
                        textDecoration: "none",
                        // float: "right",
                        backgroundColor: "#CDEAC0",
                        margin: "20px",
                      }}
                      onClick={() =>
                        SocketIOGetVoteResult(
                          room_name,
                          storedUser.username,
                          true,
                          false
                        )
                      }
                      disabled={timeRemaining !== 0}
                    >
                      Get Votes + Continue
                    </Button>
                  </Grid>
                )}
              </Grid>
            )}
          </div>

          {/* <div style={{ marginTop: "100px" }}> */}
          <div style={{ overflowX: "auto" }}>
            <PitchSelector
              selected_duration={duration}
              setPitch={setPitch}
              setDuration={setDuration}
              lockedIn={lockedIn}
              compositionEnded={compositionEnded}
            />
          </div>

          <br />
          <Grid container spacing={2} direction="column" alignItems="center">
            {!compositionEnded && (
              <Grid item>
                <DurationSelector
                  duration={duration}
                  setDuration={setDuration}
                  lockedIn={lockedIn}
                />
              </Grid>
            )}
            <br />
            <Grid container spacing={2} direction="row" justify="center">
              <IconButton
                variant="contained"
                style={{
                  backgroundColor: "white",
                }}
                // color="secondary"
                onClick={playComposition}
              >
                <PlayArrowRoundedIcon color="inherit" fontSize="large" />
              </IconButton>
              {!compositionEnded && (
                <Grid item>
                  <Button
                    variant="contained"
                    disabled={lockedIn}
                    onClick={lockIn}
                    startIcon={lockedIn ? <LockIcon /> : <LockOpenIcon />}
                  >
                    {lockedIn ? "Vote Locked" : "Lock in Vote"}
                  </Button>
                </Grid>
              )}
            </Grid>
            <br />
            {!compositionEnded && lockedIn && (
              <>
                <CircularProgress
                  color="secondary"
                  styles={{
                    float: "left",
                    backgroundColor: "white",
                    margin: "10px",
                  }}
                />
                <Typography variant="body1" styles={{ float: "left" }}>
                  {isLeader
                    ? "Select to stop or continue the composition"
                    : "Waiting on vote results..."}
                </Typography>
                <br />
              </>
            )}
            {errorMessage && (
              <Typography variant="body1">{errorMessage}</Typography>
            )}
          </Grid>
        </>
      )}
    </div>
    /////////////////////////////////////////////////////////////////////////
  );
}

const NoteSelectionPage = withStyles(styles)(ANoteSelectionPage);
export { NoteSelectionPage };
